import React, { createContext, useEffect, useMemo } from "react";

import { useAppDispatch } from "./app/hooks";
import { setSlideCount } from "./features/deck/deckSlice";
import { Notes, Continue, SlideLayout, SlideLayoutProps } from "./Directives";
import ModeSelect from "./ModeSelect";
import Default from "./layout/Default";
import Prism from "./Prism";

type Location = "content" | "notes";

export interface Slide {
  content: React.ReactNode[];
  notes: React.ReactNode[];
  layout: React.FC<{ children?: React.ReactNode }>;
}

const slideNew = (): Slide => ({
  content: [],
  notes: [],
  layout: Default,
});

const slideClone = (slide: Slide): Slide => ({
  content: [...slide.content],
  notes: [...slide.notes],
  layout: slide.layout,
});

export const applyLayout = (slide?: Slide) =>
  slide && <slide.layout>{slide.content}</slide.layout>;

const parseSlides = (children: React.ReactNode[]): Slide[] => {
  let location: Location = "content";
  let slide = slideNew();
  const slides: Slide[] = [];

  for (const piece of children) {
    if (React.isValidElement(piece)) {
      const { type } = piece;

      if (type === Notes) {
        location = "notes";
      } else if (type === Continue) {
        slides.push(slideClone(slide));
        location = "content";
      } else if (type === SlideLayout) {
        const layoutPiece = piece as React.ReactElement<SlideLayoutProps>;
        slide.layout = layoutPiece.props.use;
      } else if (type === "hr") {
        slides.push(slide);
        location = "content";
        slide = slideNew();
      } else {
        slide[location].push(piece);
      }
    }
  }

  if (slide.content.length !== 0) {
    slides.push(slide);
  }

  return slides;
};

export const SlidesContext = createContext<Slide[]>([]);

interface SplitSlidesProps {
  children?: React.ReactNode;
}

const SplitSlides: React.FC<SplitSlidesProps> = ({ children }) => {
  const slides = useMemo(
    () => parseSlides(React.Children.toArray(children)),
    [children],
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSlideCount(slides.length));
  }, [dispatch, slides]);

  return (
    <SlidesContext.Provider value={slides}>
      <ModeSelect />
    </SlidesContext.Provider>
  );
};

interface PreProps {
  children?: React.ReactNode;
}

const Pre: React.FC<PreProps> = ({ children }) => {
  const kids = React.Children.map(children, (child) => {
    if (!(React.isValidElement(child) && child.props)) {
      return child;
    }

    if (child.type !== "code") {
      return child;
    }

    return <Prism {...child.props} />;
  });

  return <pre>{kids}</pre>;
};

interface DeckProps {
  MdxDocument: React.FC<{ components: Record<string, unknown> }>;
}

const Deck: React.FC<DeckProps> = ({ MdxDocument }) => (
  <MdxDocument components={{ SplitSlides, pre: Pre }} />
);

export default Deck;
