window.Prism = window.Prism || {};
window.Prism.manual = true;

import Prism from "prismjs";
import "prismjs/components/prism-c";
import "prismjs/components/prism-rust";
import "prismjs/components/prism-toml";
import "prismjs/plugins/keep-markup/prism-keep-markup";

export default Prism;
